// These imports have no CSS output, but provide variables, mixins and helpers to aid in construction of a design cutup.
@import "imports/variables.scss";
@import "imports/functions.scss";
@import "imports/mixins.scss";
@import "imports/placeholders.scss";
@import "imports/typography.scss";

// Box size all the things
// https://squizmap.squiz.net/imp-bp/6400
// http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
// == Start box-sizing reset ==
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  @include sq-border-box;
}

// == End box-sizing reset ==

/*
--------------------
Modules
--------------------
*/
@import "../modules/_Global/css/global.scss";
