@font-face {
  font-family: FSMe;
  src: url("assets/fonts/FSMeWeb-Bold.eot");
  src:
    url("assets/fonts/FSMeWeb-Bold.eot#iefix") format("embedded-opentype"),
    url("assets/fonts/FSMeWeb-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

// BoldItalic
@font-face {
  font-family: FSMe;
  src: url("assets/fonts/FSMeWeb-BoldItalic.eot");
  src:
    url("assets/fonts/FSMeWeb-BoldItalic.eot#iefix") format("embedded-opentype"),
    url("assets/fonts/FSMeWeb-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

// Heavy
@font-face {
  font-family: FSMe;
  src: url("assets/fonts/FSMeWeb-Heavy.eot");
  src:
    url("assets/fonts/FSMeWeb-Heavy.eot#iefix") format("embedded-opentype"),
    url("assets/fonts/FSMeWeb-Heavy.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

// HeavyItalic
@font-face {
  font-family: FSMe;
  src: url("assets/fonts/FSMeWeb-HeavyItalic.eot");
  src:
    url("assets/fonts/FSMeWeb-HeavyItalic.eot#iefix") format("embedded-opentype"),
    url("assets/fonts/FSMeWeb-HeavyItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

// Italic
@font-face {
  font-family: FSMe;
  src: url("assets/fonts/FSMeWeb-Italic.eot");
  src:
    url("assets/fonts/FSMeWeb-Italic.eot#iefix") format("embedded-opentype"),
    url("assets/fonts/FSMeWeb-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

// Light
@font-face {
  font-family: FSMe;
  src: url("assets/fonts/FSMeWeb-Light.eot");
  src:
    url("assets/fonts/FSMeWeb-Light.eot#iefix") format("embedded-opentype"),
    url("assets/fonts/FSMeWeb-Light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

// LightItalic
@font-face {
  font-family: FSMe;
  src: url("assets/fonts/FSMeWeb-LightItalic.eot");
  src:
    url("assets/fonts/FSMeWeb-LightItalic.eot#iefix") format("embedded-opentype"),
    url("assets/fonts/FSMeWeb-LightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

// Regular
@font-face {
  font-family: FSMe;
  src: url("assets/fonts/FSMeWeb-Regular.eot");
  src:
    url("assets/fonts/FSMeWeb-Regular.eot#iefix") format("embedded-opentype"),
    url("assets/fonts/FSMeWeb-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: SinkinSans;
  src: url("assets/fonts/SinkinSans-300Light-webfont.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: SinkinSans;
  src: url("assets/fonts/SinkinSans-700Bold-webfont.woff") format("woff");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: SinkinSans;
  src: url("assets/fonts/SinkinSans-700Bold-webfont.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: SinkinSans;
  src: url("assets/fonts/SinkinSans-400Regular-webfont.woff") format("woff");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: SinkinSans;
  src: url("assets/fonts/SinkinSans-400Regular-webfont.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: Brauer;
  src: url("assets/fonts/lineto-brauerneue-regular.eot");
  src:
    url("assets/fonts/lineto-brauerneue-regular.eot#iefix") format("embedded-opentype"),
    url("assets/fonts/lineto-brauerneue-regular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Brauer;
  src: url("assets/fonts/lineto-brauerneue-italic.eot");
  src:
    url("assets/fonts/lineto-brauerneue-italic.eot#iefix") format("embedded-opentype"),
    url("assets/fonts/lineto-brauerneue-italic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Brauer;
  src: url("assets/fonts/lineto-brauerneue-boldItalic.eot");
  src:
    url("assets/fonts/lineto-brauerneue-boldItalic.eot#iefix") format("embedded-opentype"),
    url("assets/fonts/lineto-brauerneue-boldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Brauer;
  src: url("assets/fonts/lineto-brauerneue-bold.eot");
  src:
    url("assets/fonts/lineto-brauerneue-bold.eot#iefix") format("embedded-opentype"),
    url("assets/fonts/lineto-brauerneue-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Brauer;
  src: url("assets/fonts/lineto-brauerneue-black.eot");
  src:
    url("assets/fonts/lineto-brauerneue-black.eot#iefix") format("embedded-opentype"),
    url("assets/fonts/lineto-brauerneue-black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Brauer;
  src: url("assets/fonts/lineto-brauerneue-blackItalic.eot");
  src:
    url("assets/fonts/lineto-brauerneue-blackItalic.eot#iefix") format("embedded-opentype"),
    url("assets/fonts/lineto-brauerneue-blackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}
